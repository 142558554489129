import React from "react";
import { useIntl } from "react-intl";

import styles from "./index.module.scss";

import ArrowLeftImg from "../../../../assets/presentation/images/arrow-left.svg";
import H1 from "../../H1";
import LogoMediumImg from "../../../../assets/presentation/images/logo-medium.svg";
import LockSmallImg from "../../../../assets/presentation/images/lock-small.svg";
import Body from "../../Body";
import Caption from "../../Caption";
import LockRedImg from "../../../../assets/presentation/images/lock-red.svg";
import BankRedImg from "../../../../assets/presentation/images/bank-red.svg";
import PartnerRedImg from "../../../../assets/presentation/images/partner-red.svg";
import CertificateRedImg from "../../../../assets/presentation/images/certificate-red.svg";

function Security(props) {
    const intl = useIntl();

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div 
                    className={styles.left}
                    onClick={() => props.setVisibility(false)}
                >
                    <img
                        src={ArrowLeftImg}
                        alt="back"
                    />
                    <Caption opacity={0.4}>
                        {intl.formatMessage({ id: "global.presentation.back" })}
                    </Caption>
                </div>
            </div>
            <div className={styles["content-container"]}>
                <div className={styles.content}>
                    <H1>
                        {intl.formatMessage({ id: "global.presentation.menu.security" })}
                    </H1>
                    <div className={styles["secure-payment"]}>
                        <div className={styles.left}>
                            <img src={LogoMediumImg} alt="logo-medium" />
                            <img className={styles.absolute} src={LockSmallImg} alt="lock-small" />
                        </div>
                        <div className={styles.right}>
                            <Body>
                                {intl.formatMessage({ id: "global.presentation.menu.securedPayment" })}
                            </Body>
                        </div>
                    </div>
                    <div className={styles.options}>
                        <div
                            className={styles.option}
                        >
                            <div className={styles.left}>
                                <img src={LockRedImg} alt="lock-red" />
                            </div>
                            <div className={styles.right}>
                                <Body>
                                    {intl.formatMessage({ id: "global.presentation.menu.ssl.title" })}
                                </Body>
                                <Caption>
                                    {intl.formatMessage({ id: "global.presentation.menu.ssl.description" })}
                                </Caption>
                            </div>
                        </div>
                        <div className={styles.hr} />
                        <div
                            className={styles.option}
                        >
                            <div className={styles.left}>
                                <img src={BankRedImg} alt="lock-red" />
                            </div>
                            <div className={styles.right}>
                                <Body>
                                    {intl.formatMessage({ id: "global.presentation.menu.licence.title" })}
                                </Body>
                                <Caption>
                                    {intl.formatMessage({ id: "global.presentation.menu.licence.description" })}
                                </Caption>
                            </div>
                        </div>
                        <div className={styles.hr} />
                        <div
                            className={styles.option}
                        >
                            <div className={styles.left}>
                                <img src={PartnerRedImg} alt="lock-red" />
                            </div>
                            <div className={styles.right}>
                                <Body>
                                    {intl.formatMessage({ id: "global.presentation.menu.partner.title" })}
                                </Body>
                                <Caption>
                                    {intl.formatMessage({ id: "global.presentation.menu.partner.description" })}
                                </Caption>
                            </div>
                        </div>
                        <div className={styles.hr} />
                        <div
                            className={styles.option}
                        >
                            <div className={styles.left}>
                                <img src={CertificateRedImg} alt="lock-red" />
                            </div>
                            <div className={styles.right}>
                                <Body>
                                    {intl.formatMessage({ id: "global.presentation.menu.certificate.title" })}
                                </Body>
                                <Caption>
                                    {intl.formatMessage({ id: "global.presentation.menu.certificate.description" })}
                                </Caption>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Security;