import React from "react";

import styles from "./Caption.module.scss";

function Caption(props) {
    return (
        <span 
            style={{ opacity: props.opacity }}
            className={styles.caption}
        >
            {props.children}
        </span>
    );
}

export default Caption;