import React from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

import styles from "./index.module.scss";

import { getByCode, LanguageEnum } from "../../../../enums/LanguageEnum";

import H1 from "../../H1";
import ArrowLeftImg from "../../../../assets/presentation/images/arrow-left.svg";
import * as languageAction from "../../../../store/actions/languageAction";
import Body from "../../Body";
import Caption from "../../Caption";

function Language(props) {
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <div className={styles.left} onClick={() => props.setVisibility(false)}>
          <img src={ArrowLeftImg} alt="back" />
          <Caption opacity={0.4}>
            {intl.formatMessage({ id: "global.presentation.back" })}
          </Caption>
        </div>
      </div>
      <div className={styles["content-container"]}>
        <div className={styles.content}>
          <H1>
            {intl.formatMessage({ id: "global.presentation.menu.language" })}
          </H1>
          <div className={styles.overflow}>
            <div className={styles.options}>
              {LanguageEnum.map((l) => {
                return (
                  <div
                    key={l.code}
                    className={styles.option}
                    onClick={() => {
                      sessionStorage.setItem("language", l.code);
                      dispatch(languageAction.changeLanguage(l.code));
                    }}
                  >
                    <Body semiBold>
                      {intl.formatMessage({
                        id: `global.presentation.menu.language.${l.code}`,
                      })}
                    </Body>
                    <div className={styles.right}>{l.logo}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Language;
