import React from "react";

import styles from "./H1.module.scss";

function H1(props) {
    return (
        <h1 className={[
            styles.h1,
            props.semiBold ? styles["semi-bold"] : ""
        ].join(" ")}>
            {props.children}
        </h1>
    );
}

export default H1;