import React, { useState } from "react";
import { useIntl } from "react-intl";

import styles from "./index.module.scss";

import { getObjectByName } from "../../../../enums/CurrencyEnum";

import ArrowLeftImg from "../../../../assets/presentation/images/arrow-left.svg";
import Caption from "../../Caption";
import H1 from "../../H1";
import Body from "../../Body";

function OrderDetail(props) {
    const intl = useIntl();

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div 
                    onClick={() => props.setVisibility(false)}
                    className={styles.left}
                >
                    <img
                        src={ArrowLeftImg}
                        alt="back"
                    />
                    <Caption opacity={0.4}>
                        {intl.formatMessage({ id: "global.presentation.back" })}
                    </Caption>
                </div>
            </div>
            <div className={styles["content-container"]}>
                <div className={styles.content}>
                    <div className={styles.summary}>
                        <div className={styles.overflow}>
                            <div className={styles["summary-content"]}>
                                <Caption>
                                    {intl.formatMessage({ id: "global.presentation.menu.title" })}
                                </Caption>
                                <H1>
                                    {`${props.data.totalAmount / 100} ${getObjectByName(props.data.currency).symbol}`}
                                </H1>
                                <div className={styles.hr}></div>
                                <Caption>
                                    {`${intl.formatMessage({ id: "global.presentation.menu.transactionId" })}: ${props.data.transactionId}`}
                                </Caption>
                                <Caption>
                                    {`${intl.formatMessage({ id: "global.presentation.menu.orderId" })}: ${props.data.orderNumber}`}
                                </Caption>
                                <div className={styles.hr}></div>
                                <div className={styles["items-title"]}>
                                    <Body semiBold>
                                        {intl.formatMessage({ id: "global.presentation.menu.orderItems" })}
                                    </Body>
                                </div>
                                {
                                    props.data.items.map(i =>
                                        <div
                                            key={i.name}
                                            className={styles.item}
                                        >
                                            <div className={styles.information}>
                                                <Caption>
                                                    {i.name}
                                                </Caption>
                                                <div className={styles.right}>
                                                    <Caption>
                                                        {`${(i.amount * i.count) / 100} ${getObjectByName(props.data.currency).symbol}`}
                                                    </Caption>
                                                </div>
                                            </div>
                                            <div className={styles.count}>
                                                <Caption opacity={0.4}>
                                                    {`${i.count} ks`}
                                                </Caption>
                                                <div className={styles.hr}></div>
                                            </div>
                                        </div>

                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderDetail;