import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

import styles from "./index.module.scss";

import { getObjectByName } from "../../../enums/CurrencyEnum";
import { getByCode, LanguageEnum } from "../../../enums/LanguageEnum";

import LogoImg from "../../../assets/presentation/images/logo.svg";
import LogoSmallImg from "../../../assets/presentation/images/logo-small.svg";
import LogoMediumImg from "../../../assets/presentation/images/logo-medium.svg";
import ArrowLeftImg from "../../../assets/presentation/images/arrow-left.svg";
import MenuImg from "../../../assets/presentation/images/menu.svg";
import Caption from "../Caption";
import H1 from "../H1";
import OrderImg from "../../../assets/presentation/images/order.svg";
import Body from "../Body";
import LockImg from "../../../assets/presentation/images/lock.svg";
import LockSmallImg from "../../../assets/presentation/images/lock-small.svg";
import LockRedImg from "../../../assets/presentation/images/lock-red.svg";
import BankRedImg from "../../../assets/presentation/images/bank-red.svg";
import PartnerRedImg from "../../../assets/presentation/images/partner-red.svg";
import CertificateRedImg from "../../../assets/presentation/images/certificate-red.svg";
import * as languageAction from "../../../store/actions/languageAction";
import OrderDetail from "./OrderDetail";
import Menu from "./Menu";

function WidgetBase(props) {
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isOrderDetailVisible, setIsOrderDetailVisible] = useState(false);
    const [isLanguageVisible, setIsLanguageVisible] = useState(false);
    const [isSecurityVisible, setIsSecurityVisible] = useState(false);

    const intl = useIntl();
    const language = useSelector((state) => state.language.language);
    const dispatch = useDispatch()

    const showBack = () => {
        if (props.showBack)
            return <img
                onClick={props.onBack}
                src={ArrowLeftImg}
                alt="back"
            />;
        else
            return "";
    }

    const showMenu = () => {
        if (props.showMenu && props.data !== undefined && props.data !== null)
            return <img
                onClick={() => setIsMenuVisible(true)}
                src={MenuImg}
                alt="menu"
            />;
    }

    const showLogo = (logo) => {
        if (logo !== null && logo !== undefined)
            return <img
                src={"data:image/png;base64, " + logo}
                alt="Logo"
            />;
        else
            return <img src={LogoImg} alt="logo" />;
    };

    if (isMenuVisible)
        return <Menu
            data={props.data}
            setVisibility={setIsMenuVisible}
        />;

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.left}>
                    {showBack()}
                </div>
                <div className={styles.center}>
                    {showLogo(props.data?.clientLogo)}
                </div>
                <div className={styles.right}>
                    {showMenu()}
                </div>
            </div>
            <div className={styles["content-container"]}>
                <div className={styles.content}>
                    {props.children}
                </div>
            </div>
            <div className={styles.footer}>
                <img src={LogoSmallImg} alt="logo-small" />
                <Caption
                    opacity={0.4}
                >
                    {intl.formatMessage({ id: "global.presentation.securedPayment" })}
                </Caption>
            </div>
        </div>
    );
}

export default WidgetBase;