import React from "react";
import { Route, Switch } from "react-router";
import { RouterEnum } from "../enums/RouterEnum";

// import ProtectedRoute from "./ProtectedRoute";

const Completed = React.lazy(() => import("../pages/presentation/Completed"));
const Canceled = React.lazy(() => import("../pages/presentation/Canceled"));
const InternalServerError = React.lazy(() =>
  import("../pages/presentation/InternalServerError")
);
const InvalidRequest = React.lazy(() =>
  import("../pages/presentation/InvalidRequest")
);
const NotFound = React.lazy(() => import("../pages/presentation/NotFound"));
const Processing = React.lazy(() => import("../pages/presentation/Processing"));
const ServiceUnavailable = React.lazy(() =>
  import("../pages/presentation/ServiceUnavailable")
);
const Timeouted = React.lazy(() => import("../pages/presentation/Timeouted"));
const Unauthorized = React.lazy(() =>
  import("../pages/presentation/Unauthorized")
);
const WaitForConfirmation = React.lazy(() =>
  import("../pages/presentation/WaitForConfirmation")
);
const Widget = React.lazy(() => import("../pages/presentation/Widget"));
const WidgetApplePay = React.lazy(() =>
  import("../pages/presentation/WidgetApplePay")
);
const WidgetBank = React.lazy(() => import("../pages/presentation/WidgetBank"));
const WidgetCard = React.lazy(() => import("../pages/presentation/WidgetCard"));
const WidgetQrCode = React.lazy(() =>
  import("../pages/presentation/WidgetQrCode")
);
const WidgetGooglePay = React.lazy(() =>
  import("../pages/presentation/WidgetGooglePay")
);
const WidgetCancel = React.lazy(() =>
import("../pages/presentation/WidgetCancel")
);
const TransactionStatus = React.lazy(() =>
  import("../pages/presentation/TransactionStatus")
);

function Routers(props) {
  const presentationRouter = () => {
    return (
      <Switch>
        <Route
          exact
          path={RouterEnum.Canceled.Path}
          render={() => <Canceled />}
        />
        <Route
          exact
          path={RouterEnum.Completed.Path}
          render={() => <Completed />}
        />
        <Route
          exact
          path={RouterEnum.InternalServerError.Path}
          render={() => <InternalServerError />}
        />
        <Route
          exact
          path={RouterEnum.InvalidRequest.Path}
          render={() => <InvalidRequest />}
        />
        <Route
          exact
          path={RouterEnum.Processing.Path}
          render={() => <Processing />}
        />
        <Route
          exact
          path={RouterEnum.ServiceUnavailable.Path}
          render={() => <ServiceUnavailable />}
        />
        <Route
          exact
          path={RouterEnum.Timeouted.Path}
          render={() => <Timeouted />}
        />
        <Route
          exact
          path={RouterEnum.Unauthorized.Path}
          render={() => <Unauthorized />}
        />
        <Route
          exact
          path={RouterEnum.WaitForConfirmation.Path}
          render={() => <WaitForConfirmation />}
        />
        <Route exact path={RouterEnum.Widget.Path} render={() => <Widget />} />
        <Route
          exact
          path={RouterEnum.WidgetApplePay.Path}
          render={() => <WidgetApplePay />}
        />
        <Route
          exact
          path={RouterEnum.WidgetBank.Path}
          render={() => <WidgetBank />}
        />
        <Route
          exact
          path={RouterEnum.WidgetCard.Path}
          render={() => <WidgetCard />}
        />
        <Route
          exact
          path={RouterEnum.WidgetQrCode.Path}
          render={() => <WidgetQrCode />}
        />
        <Route
          exact
          path={RouterEnum.WidgetGooglePay.Path}
          render={() => <WidgetGooglePay />}
        />
        <Route
          exact
          path={RouterEnum.WidgetCancel.Path}
          render={() => <WidgetCancel />}
        />
         <Route
          exact
          path={RouterEnum.TransactionStatus.Path}
          render={() => <TransactionStatus />}
        />
        <Route render={() => <NotFound />} />
      </Switch>
    );
  };

  return presentationRouter();
}

export default Routers;
