import SkImg from "../assets/presentation/images/language-sk.svg";
import CsImg from "../assets/presentation/images/language-cs.svg";
import EnImg from "../assets/presentation/images/language-en.svg";
import DeImg from "../assets/presentation/images/language-de.svg";
import FrImg from "../assets/presentation/images/language-fr.svg";
import HuImg from "../assets/presentation/images/language-hu.svg";
import PlImg from "../assets/presentation/images/language-pl.svg";
import RoImg from "../assets/presentation/images/language-ro.svg";
import SlImg from "../assets/presentation/images/language-sl.svg";

export const LanguageEnum = Object.freeze([
  { code: "sk", logo: <img src={SkImg} alt="sk" /> },
  { code: "cs", logo: <img src={CsImg} alt="cs" /> },
  { code: "en", logo: <img src={EnImg} alt="en" /> },
  { code: "de", logo: <img src={DeImg} alt="de" /> },
  { code: "fr", logo: <img src={FrImg} alt="fr" /> },
  { code: "hu", logo: <img src={HuImg} alt="hu" /> },
  { code: "pl", logo: <img src={PlImg} alt="pl" /> },
  { code: "ro", logo: <img src={RoImg} alt="ro" /> },
  { code: "sl", logo: <img src={SlImg} alt="sl" /> },
]);

export const getByCode = (code) => {
  return (
    LanguageEnum.find((language) => {
      return language.code === code;
    }) || LanguageEnum[0]
  );
};

export const languageCodeExists = (code) => {
  return LanguageEnum.some((language) => {
    return language.code === code ? true : false;
  });
};
