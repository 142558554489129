import React, { Suspense, useEffect } from 'react';
import Routers from './components/Routers';
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { IntlProvider } from "react-intl";

import PresentationLayout from './components/presentation/PresentationLayout';
import global from "./translations/global.json";
import presenationGlobal from "./translations/presentation/global.json";
import WidgetBase from './components/presentation/WidgetBase';
import Loader from './components/presentation/Loader';

function App(props) {
  const language = useSelector((state) => state.language.language);
  const history = useHistory();
  const location = useLocation();

  let combinedTranslation = {
    "en": {
      ...global["en"],
      ...presenationGlobal["en"]
    },
    "sk": {
      ...global["sk"],
      ...presenationGlobal["sk"],
    },
    "cs": {
      ...global["cs"],
      ...presenationGlobal["cs"],
    },
    "hu": {
      ...global["hu"],
      ...presenationGlobal["hu"],
    },
    "pl": {
      ...global["pl"],
      ...presenationGlobal["pl"],
    },
    "ro": {
      ...global["ro"],
      ...presenationGlobal["ro"],
    },
    "de": {
      ...global["de"],
      ...presenationGlobal["de"],
    },
    "fr": {
        ...global["fr"],
        ...presenationGlobal["fr"],
    },
    "sl": {
        ...global["sl"],
        ...presenationGlobal["sl"],
    }
  }

  useEffect(() => {
    if (location.pathname.includes("www"))
      history.push(location.pathname.replace("www.", ""));
  }, []);

  return (
    <IntlProvider
      key={language}
      locale={language}
      messages={combinedTranslation[language]}
    >
      <Suspense fallback={
        <WidgetBase
          showBack={false}
          showMenu={false}
        >
          <Loader />
        </WidgetBase>
      }>
        <PresentationLayout>
          <Suspense fallback={
            <WidgetBase
              showBack={false}
              showMenu={false}
            >
              <Loader />
            </WidgetBase>
          }>
            <Routers />
          </Suspense>
        </PresentationLayout>
      </Suspense>
    </IntlProvider>
  );
}

export default App;
