import React from "react";
import { useIntl } from "react-intl";

import styles from "./Loader.module.scss";

import H1 from "./H1";
import Spinner from "./Spinner";

function Loader(props) {
    const intl = useIntl();

    return (
        <div className={styles.main}>
            <div className={styles.content}>
                <Spinner />
                <H1>
                    {intl.formatMessage({ id: "global.presentation.loader.title" })}
                </H1>
            </div>
        </div>
    );
}


export default Loader;