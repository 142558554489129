import React from "react";

import styles from "./Body.module.scss";

function Body(props) {
    return (
        <span 
            className={[
                styles.body,
                props.semiBold ? styles["semi-bold"] : ""
            ].join(" ")}
        >
            {props.children}
        </span>
    );
}

export default Body;