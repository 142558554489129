export const RouterEnum = Object.freeze({
    Canceled: {
        Path: "/canceled"
    },
    Completed: {
        Path: "/completed"
    },
    InternalServerError: {
        Path: "/internal-server-error"
    },
    InvalidRequest: {
        Path: "/invalid-request"
    },
    Processing: {
        Path: "/processing"
    },
    ServiceUnavailable: {
        Path: "/service-unavailable"
    },
    Timeouted: {
        Path: "/timeouted"
    },
    Unauthorized: {
        Path: "/unauthorized"
    },
    WaitForConfirmation: {
        Path: "/waiting-for-confirmation"
    },
    Widget: {
        Path: "/widget",
        generatePath: (securityHash) => `/widget?sh=${securityHash}`,
    },
    WidgetApplePay: {
        Path: "/widget/apple-pay",
        generatePath: (securityHash) => `/widget/apple-pay?sh=${securityHash}`,
    },
    WidgetBank: {
        Path: "/widget/bank",
        generatePath: (securityHash) => `/widget/bank?sh=${securityHash}`,
    },
    WidgetCard: {
        Path: "/widget/card",
        generatePath: (securityHash) => `/widget/card?sh=${securityHash}`,
    },
    WidgetGooglePay: {
        Path: "/widget/google-pay",
        generatePath: (securityHash) => `/widget/google-pay?sh=${securityHash}`,
    },
    WidgetQrCode: {
        Path: "/widget/qr-code",
        generatePath: (securityHash) => `/widget/qr-code?sh=${securityHash}`,
    },
    WidgetCancel: {
        Path: "/widget/cancel",
        generatePath: (securityHash) => `/widget/cancel?sh=${securityHash}`,
    },
    TransactionStatus: {
        Path: "/status"
    },
})