import React from "react";

import styles from "./PresentationLayout.module.scss";

function PresentationLayout(props) {
  const inIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  return (
    <div
      className={[
        styles.content,
        inIframe() ? styles["iframe-content"] : "",
      ].join(" ")}
    >
      {props.children}
    </div>
  );
}

export default PresentationLayout;
